import { getCookie } from 'cookies-next'
import { useUnit } from 'effector-react'
import { GetServerSidePropsContext } from 'next'
import { useTranslations } from 'next-intl'
import { useEffect } from 'react'

import { appStarted, clientCookiesSet } from '@/shared-events'

const Custom404 = () => {
  const t = useTranslations()
  const [clientSideAppStarted, cookiesSet] = useUnit([appStarted, clientCookiesSet])

  useEffect(() => {
    cookiesSet((getCookie('access') as string) ?? '')
    clientSideAppStarted()
  }, [clientSideAppStarted, cookiesSet])

  return (
    <>
      <div className="align-center flex flex-col items-center pt-10 font-golos">
        {/*<Link prefetch={false} href="/" legacyBehavior>*/}
        {/*  <a className="font-avenir font-medium" rel="nofollow" style={{ fontSize: '22px' }}>*/}
        {/*    Statsnet*/}
        {/*  </a>*/}
        {/*</Link>*/}
        <span className="" style={{ fontSize: '200px' }}>
          404
        </span>
        <h1 className="text-3xl">{t(`page_not_found`)}</h1>
        <p className="text-gray-500">{t(`but_have_another_link`)}</p>
        {/*<Link href="/states" className="text-statsnet text-xl p-1 pr-4 hover:opacity-70 sm:pr-4 sm:p-0 mb-5">*/}
        {/*  {t('companies')}*/}
        {/*</Link>*/}

        {/*<SearchSnippets reduced />*/}
      </div>
    </>
  )
}

export const getStaticProps = async (ctx: GetServerSidePropsContext) => {
  const locale = ctx.locale

  const messages = (await import(`../../messages/${locale}/common.json`)).default

  return {
    props: {
      messages,
    },
  }
}

export default Custom404
